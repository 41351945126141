

















































































































import { Vue, Component } from 'vue-property-decorator'
import pageTitle from '@/components/common/pageTitle.vue'
import SecurityText from '@/components/common/SecurityText/index.vue'
import _ from 'lodash'
import { is } from '@/utils/helpers'
import mitt from '@/utils/mitt'

@Component({
  name: 'index',
  components: {
    pageTitle,
    SecurityText
  }
})
export default class GroupLeasingPage extends Vue {
  private loading = false
  private noDataImg = require('@/assets/images/dashboard/noData.png')
  private filterData: AnyObj = { searchType: 'landlordName' }
  pagination = { pageSize: 20, currentPage: 1 }
  selectList = [
    { label: '户主姓名', value: 'landlordName' },
    { label: '门牌号', value: 'name' }
  ]
  houses: AnyObj[] = []
  total = 0

  get rootConfig() {
    return this.$store.state.config?.root
  }

  get root() {
    return this.rootConfig ? this.rootConfig.id : this.$store.state.user.scope
  }

  get placeholder() {
    return this.filterData.searchType === 'landlordName' ? '请输入户主姓名' : '请输入门牌号'
  }
  get state() {
    return this.$store.state.overmannedHouses
  }

  get params() {
    const { currentPage, pageSize } = this.pagination
    const val = this.filterData
    const par: any = {
      areaIds: val.areaIds && val.areaIds.length > 0 ? val.areaIds : [this.root],
      offset: (currentPage - 1) * pageSize,
      limit: pageSize,
      status: 'overmanned'
    }
    par[val.searchType] = val.searchText ? val.searchText.replace(/\s+/g, '') : null
    return par
  }

  mounted() {
    this.filter()
    mitt.on(['residents:change', 'houses/residents:change', 'houses/rooms:change'], () =>
      this.fetch()
    )
  }

  fetch() {
    this.fetchHouses()
  }

  async fetchHouses() {
    this.loading = true

    const params = _.pickBy(this.params, is.ava)
    const { data } = await this.$api.europa.getHouseLists(params)

    this.loading = false

    if (data.code === 0) {
      this.houses = data.data
      this.total = data.count
    }
  }

  filter() {
    this.pagination.currentPage = 1
    this.fetch()
  }

  private houseInfo(obj: AnyObj) {
    this.$router.push({ name: '房屋信息查看', params: { id: obj.id } })
  }
}
